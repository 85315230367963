var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('modal', {
    ref: "modal",
    attrs: {
      "title": "Требуется подтверждение",
      "width": 400,
      "actions": _vm.actions
    }
  }, [_c('span', [_vm._v(" Вы уверены, что хотите удалить товары? ")])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };