import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.find.js";
import "core-js/modules/esnext.iterator.some.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('WarningAllLK'), _c('WarningRepricer'), _c('has-tokens', [!_vm.loading ? _c('report-page', [!_vm.$route.params.id ? _c('main-title', [_vm._v(" Создание сценария ")]) : _c('main-title', [_vm._v(" Редактирование сценария: " + _vm._s(_vm.oldName) + " ")]), _c('div', {
    staticClass: "new-script__step"
  }, [_c('h3', {
    staticClass: "new-script__step__title"
  }, [_vm._v("Настройки сценария")]), _c('div', {
    staticClass: "settings__block"
  }, [_c('h3', {
    staticClass: "settings__block__title"
  }, [_c('span', [_vm._v("Название сценария")])]), _c('div', {
    staticClass: "settings__block__content"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.name,
      expression: "name"
    }],
    staticClass: "input",
    attrs: {
      "type": "text",
      "placeholder": "Например, Ответ на хорошие отзывы"
    },
    domProps: {
      "value": _vm.name
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.name = $event.target.value;
      }
    }
  })])]), !_vm.$route.params.id ? _c('div', {
    staticClass: "settings__block"
  }, [_c('h3', {
    staticClass: "settings__block__title"
  }, [_c('span', [_vm._v("Выбрать магазин")])]), _vm.hasOzon && _vm.hasWb ? _c('div', {
    staticClass: "settings__block__content",
    staticStyle: {
      "margin-bottom": "10px"
    }
  }, [_c('app-select', {
    staticClass: "tokens__select",
    attrs: {
      "items": _vm.select_marketplace_items,
      "fullwidth": ""
    },
    model: {
      value: _vm.marketplace,
      callback: function ($$v) {
        _vm.marketplace = $$v;
      },
      expression: "marketplace"
    }
  })], 1) : _vm._e(), _c('div', {
    staticClass: "settings__block__content"
  }, [_c('app-select', {
    staticClass: "tokens__select",
    attrs: {
      "items": _vm.select_tokens_items
    },
    model: {
      value: _vm.token,
      callback: function ($$v) {
        _vm.token = $$v;
      },
      expression: "token"
    }
  })], 1)]) : _vm._e()]), _c('div', {
    staticClass: "new-script__step"
  }, [_c('h3', {
    staticClass: "new-script__step__title"
  }, [_vm._v("Тип сценария")]), _c('div', {
    staticClass: "settings__block"
  }, [_c('div', {
    staticClass: "settings__block__content"
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "flex-direction": "column"
    }
  }, _vm._l(_vm.scriptTypes, function (item, index) {
    return _c('label', {
      key: index,
      staticClass: "new-script__type"
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.type,
        expression: "type"
      }],
      attrs: {
        "type": "radio",
        "name": "type"
      },
      domProps: {
        "value": item.slug,
        "checked": _vm._q(_vm.type, item.slug)
      },
      on: {
        "change": function ($event) {
          _vm.type = item.slug;
        }
      }
    }), _c('span', [_vm._v(_vm._s(item.name))])]);
  }), 0)])]), _c('a', {
    staticClass: "link",
    staticStyle: {
      "margin-top": "20px"
    },
    attrs: {
      "href": "https://www.help.salesfinder.ru/repriser-script",
      "target": "_blank"
    }
  }, [_c('img', {
    staticStyle: {
      "margin-right": "5px"
    },
    attrs: {
      "src": require(`@/assets/images/icons/question.svg`),
      "width": "20",
      "height": "20",
      "alt": "Question"
    }
  }), _vm._v(" Инструкция по настройке сценариев ")])]), _vm._l(_vm.scriptTypes, function (item, index) {
    return _c('div', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.type === item.slug && _vm.type !== 'compensation',
        expression: "type === item.slug && type !== 'compensation'"
      }],
      key: index,
      staticClass: "new-script__step"
    }, [_c('div', {
      staticClass: "new-script__step__options"
    }, [_c('div', {
      staticClass: "new-script__step__options__item"
    }, [_c('h3', {
      staticClass: "new-script__step__title"
    }, [_vm._v(" Настройка сценария (" + _vm._s(item.name) + ") ")]), _vm.errors.some(error => error.name === 'script_value') ? _c('span', {
      staticClass: "error"
    }, [_vm._v(" " + _vm._s(_vm.errors.find(error => error.name === "script_value").text) + " ")]) : _vm._e(), !item.slug.startsWith('competitor') ? _c('div', _vm._l(item.action, function (action, index) {
      return _c('div', {
        key: index,
        staticClass: "checkbox"
      }, [_c('checkbox', {
        staticClass: "checkbox-input",
        model: {
          value: action.selected,
          callback: function ($$v) {
            _vm.$set(action, "selected", $$v);
          },
          expression: "action.selected"
        }
      }), _c('div', {
        staticClass: "checkbox-texts"
      }, [_c('p', {
        staticClass: "checkbox-text"
      }, [_vm._v(" " + _vm._s(action.condition.title.replace(/{[^}]*}/g, "")) + " "), _c('input', {
        directives: [{
          name: "model",
          rawName: "v-model",
          value: action.condition.value,
          expression: "action.condition.value"
        }],
        staticClass: "input inline-input",
        attrs: {
          "type": "text",
          "placeholder": "1000",
          "disabled": !action.selected
        },
        domProps: {
          "value": action.condition.value
        },
        on: {
          "input": function ($event) {
            if ($event.target.composing) return;
            _vm.$set(action.condition, "value", $event.target.value);
          }
        }
      }), _c('app-select', {
        staticClass: "select-inline",
        attrs: {
          "items": item.condition.unit,
          "disabled": !action.selected,
          "fullwidth": ""
        },
        model: {
          value: action.condition.unit,
          callback: function ($$v) {
            _vm.$set(action.condition, "unit", $$v);
          },
          expression: "action.condition.unit"
        }
      })], 1), _vm.errors.some(error => error.name.startsWith('script_value') && error.name.split('.')[1] == index && error.name.split('.')[2] === 'condition') ? _c('span', {
        staticClass: "error"
      }, [_vm._v(" " + _vm._s(_vm.errors.find(error => error.name.startsWith("script_value") && error.name.split(".")[1] == index && error.name.split(".")[2] === "condition").text) + " ")]) : _vm._e(), _c('img', {
        staticClass: "arrow",
        attrs: {
          "src": require(`@/assets/images/icons/arrow-down.svg`),
          "alt": "Arrow"
        }
      }), _c('p', {
        staticClass: "checkbox-text"
      }, [_vm._v(" " + _vm._s(action.expression.title.replace(/{[^}]*}/g, "")) + " "), _c('input', {
        directives: [{
          name: "model",
          rawName: "v-model",
          value: action.expression.value,
          expression: "\n                                                    action.expression.value\n                                                "
        }],
        staticClass: "input inline-input",
        attrs: {
          "type": "text",
          "placeholder": "1000",
          "disabled": !action.selected
        },
        domProps: {
          "value": action.expression.value
        },
        on: {
          "input": function ($event) {
            if ($event.target.composing) return;
            _vm.$set(action.expression, "value", $event.target.value);
          }
        }
      }), _c('app-select', {
        staticClass: "select-inline",
        attrs: {
          "items": item.expression.unit,
          "disabled": !action.selected,
          "fullwidth": ""
        },
        model: {
          value: action.expression.unit,
          callback: function ($$v) {
            _vm.$set(action.expression, "unit", $$v);
          },
          expression: "action.expression.unit"
        }
      })], 1), _vm.errors.some(error => error.name.startsWith('script_value') && error.name.split('.')[1] == index && error.name.split('.')[2] === 'expression') ? _c('span', {
        staticClass: "error"
      }, [_vm._v(" " + _vm._s(_vm.errors.find(error => error.name.startsWith("script_value") && error.name.split(".")[1] == index && error.name.split(".")[2] === "expression").text) + " ")]) : _vm._e()])], 1);
    }), 0) : _c('div', {
      staticStyle: {
        "display": "flex",
        "flex-direction": "column"
      }
    }, _vm._l(item.action, function (action, index) {
      return _c('div', {
        key: index
      }, [_vm.errors.some(error => error.name.startsWith('script_value') && error.name.split('.')[1] == index && error.name.split('.')[2] === 'expression') ? _c('span', {
        staticClass: "error"
      }, [_vm._v(" " + _vm._s(_vm.errors.find(error => error.name.startsWith("script_value") && error.name.split(".")[1] == index && error.name.split(".")[2] === "expression").text) + " ")]) : _vm._e(), _c('div', {
        staticClass: "new-script__type"
      }, [_c('label', {
        staticStyle: {
          "display": "flex",
          "align-items": "center",
          "margin-right": "10px"
        }
      }, [_c('input', {
        directives: [{
          name: "model",
          rawName: "v-model",
          value: item.radio,
          expression: "item.radio"
        }],
        attrs: {
          "type": "radio",
          "name": "price"
        },
        domProps: {
          "value": action.expression.operation,
          "checked": _vm._q(item.radio, action.expression.operation)
        },
        on: {
          "change": function ($event) {
            return _vm.$set(item, "radio", action.expression.operation);
          }
        }
      }), _c('span', [_vm._v(" " + _vm._s(action.expression.title.replace(/{[^}]*}/g, "")) + " ")])]), action.expression.title.includes('value') ? _c('input', {
        directives: [{
          name: "model",
          rawName: "v-model",
          value: action.expression.value,
          expression: "action.expression.value"
        }],
        staticClass: "input inline-input",
        attrs: {
          "type": "text",
          "placeholder": "1000",
          "disabled": item.radio !== action.expression.operation
        },
        domProps: {
          "value": action.expression.value
        },
        on: {
          "input": function ($event) {
            if ($event.target.composing) return;
            _vm.$set(action.expression, "value", $event.target.value);
          }
        }
      }) : _vm._e(), action.expression.title.includes('unit') ? _c('app-select', {
        staticClass: "select-inline",
        attrs: {
          "items": item.expression.unit,
          "fullwidth": "",
          "disabled": item.radio !== action.expression.operation
        },
        model: {
          value: action.expression.unit,
          callback: function ($$v) {
            _vm.$set(action.expression, "unit", $$v);
          },
          expression: "action.expression.unit"
        }
      }) : _vm._e()], 1)]);
    }), 0)]), _c('div', {
      staticClass: "new-script__step__options__item"
    }, [_c('div', {
      staticStyle: {
        "display": "flex"
      }
    }, [item.slug.startsWith('competitor') ? _c('div', {
      staticStyle: {
        "margin-left": "20px"
      }
    }, [_c('h3', {
      staticClass: "new-script__step__title"
    }, [_vm._v(" Список SKU конкурентов ")]), _vm.errors.some(error => error.name.startsWith('sku_competitor_list')) ? _c('span', {
      staticClass: "error"
    }, [_vm._v(" " + _vm._s(_vm.errors.find(error => error.name.startsWith("sku_competitor_list")).text) + " ")]) : _vm._e(), _c('lined-textarea', {
      attrs: {
        "styles": _vm.textareaStyles,
        "limit-number": _vm.availableItems,
        "placeholder": "Введите список SKU, по одному в строке, например:\n58294056\n123975032\n682910567"
      },
      model: {
        value: _vm.skuList2,
        callback: function ($$v) {
          _vm.skuList2 = $$v;
        },
        expression: "skuList2"
      }
    }), _c('Import-btn', {
      staticStyle: {
        "margin-bottom": "20px"
      },
      attrs: {
        "tooltip": "Формат файла XLSX или XLS, список SKU по одному в строке в одной колонке",
        "tooltip-classes": "top right"
      },
      on: {
        "imported": _vm.skuList2Import
      }
    })], 1) : _vm._e()])])])]);
  }), _c('div', {
    staticClass: "new-script__step"
  }, [_vm.type === 'compensation' || _vm.type === 'competitorSpp' ? _c('div', {
    staticClass: "checkbox",
    staticStyle: {
      "align-items": "center"
    }
  }, [_c('checkbox', {
    staticClass: "checkbox-input",
    model: {
      value: _vm.walletDiscount,
      callback: function ($$v) {
        _vm.walletDiscount = $$v;
      },
      expression: "walletDiscount"
    }
  }), _c('p', {
    staticClass: "checkbox-text"
  }, [_vm._v("С учётом скидки кошелька")])], 1) : _vm._e(), _vm.marketplace.id === 'ozon' ? _c('div', {
    staticClass: "checkbox",
    staticStyle: {
      "align-items": "center"
    }
  }, [_c('checkbox', {
    staticClass: "checkbox-input",
    model: {
      value: _vm.track_promotions_off,
      callback: function ($$v) {
        _vm.track_promotions_off = $$v;
      },
      expression: "track_promotions_off"
    }
  }), _c('p', {
    staticClass: "checkbox-text"
  }, [_vm._v("Удалять из акций")])], 1) : _vm._e(), _c('h3', {
    staticClass: "new-script__step__title"
  }, [_vm._v("Контроль остатков")]), _c('div', {
    staticClass: "checkbox"
  }, [_c('checkbox', {
    staticClass: "checkbox-input",
    model: {
      value: _vm.remainder.status,
      callback: function ($$v) {
        _vm.$set(_vm.remainder, "status", $$v);
      },
      expression: "remainder.status"
    }
  }), _c('div', {
    staticClass: "checkbox-texts"
  }, [_c('p', {
    staticClass: "checkbox-text"
  }, [_vm._v(" Если остаток снижается до "), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.remainder.quantity,
      expression: "remainder.quantity"
    }],
    staticClass: "input inline-input",
    attrs: {
      "type": "text",
      "placeholder": "1000",
      "disabled": !_vm.remainder.status
    },
    domProps: {
      "value": _vm.remainder.quantity
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.remainder, "quantity", $event.target.value);
      }
    }
  }), _vm._v(" увеличиваем цену на "), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.remainder.percent,
      expression: "remainder.percent"
    }],
    staticClass: "input inline-input",
    attrs: {
      "type": "text",
      "placeholder": "10",
      "disabled": !_vm.remainder.status
    },
    domProps: {
      "value": _vm.remainder.percent
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.remainder, "percent", $event.target.value);
      }
    }
  }), _vm._v(" % ")])])], 1)]), _c('div', {
    staticClass: "new-script__step products-table-wrap",
    class: {
      compensation: _vm.type === 'compensation'
    }
  }, [_c('h3', {
    staticClass: "new-script__step__title"
  }, [_vm._v("Список товаров")]), !_vm.$route.params.id ? _c('div', [_c('p', {
    staticClass: "new-script__step__text"
  }, [_vm._v(" Подготовьте и загрузите список товаров (SKU) с минимальными и максимальными ценами. "), _c('br'), _c('br'), _vm._v(" Репрайсер при работе сценария не будет выходить за границы указанных вами цен. ")]), _c('a', {
    staticClass: "link",
    staticStyle: {
      "margin-top": "20px"
    },
    attrs: {
      "href": "https://www.help.salesfinder.ru/repriser-min-max-price",
      "target": "_blank"
    }
  }, [_c('img', {
    staticStyle: {
      "margin-right": "5px"
    },
    attrs: {
      "src": require(`@/assets/images/icons/question.svg`),
      "width": "20",
      "height": "20",
      "alt": "Question"
    }
  }), _vm._v(" Инструкция по работе с мин. и макс. ценой ")]), _c('a', {
    staticClass: "link",
    staticStyle: {
      "margin-top": "20px"
    },
    attrs: {
      "href": _vm.type === 'compensation' ? 'SalesFinder_Repricer_Products_Compensation_Example.xlsx' : 'SalesFinder_Repricer_Products_Example.xlsx'
    }
  }, [_vm._v(" Шаблон файла для загрузки товаров ")]), _c('label', {
    staticClass: "load-products",
    staticStyle: {
      "margin-bottom": "20px"
    }
  }, [_c('input', {
    ref: "file",
    staticClass: "input-file",
    attrs: {
      "type": "file",
      "accept": ".xls,.xlsx"
    },
    on: {
      "change": _vm.handleFileSelect
    }
  }), _c('a', {
    staticClass: "btn-outline btn-width-auto import__btn"
  }, [_vm._v(" Загрузить из XLSX "), _c('Tooltip', {
    attrs: {
      "position": "top right"
    }
  }, [_vm._v(" Формат файла XLSX или XLS, три колонки, первая - SKU, вторая - минимальная цена, третья - максимальная цена ")])], 1)])]) : _c('div', [_c('label', {
    staticClass: "load-products",
    staticStyle: {
      "margin-bottom": "20px"
    }
  }, [_c('input', {
    ref: "file",
    staticClass: "input-file",
    attrs: {
      "type": "file",
      "accept": ".xls,.xlsx"
    },
    on: {
      "change": _vm.handleFileSelect
    }
  }), _c('a', {
    staticClass: "btn-outline btn-width-auto import__btn"
  }, [_vm._v(" + Добавить новые товары (XLSX) "), _c('Tooltip', {
    attrs: {
      "position": "top right"
    }
  }, [_vm._v(" Формат файла XLSX или XLS, три колонки, первая - SKU, вторая - минимальная цена, третья - максимальная цена ")])], 1)])]), _c('div', [_vm.usedSku.length > 0 ? _c('div', {
    staticClass: "error"
  }, [_vm._v(" Сценарий не был создан, следующие sku уже используется в другом сценарии или аккаунте… ")]) : _vm._e(), _vm.usedSku.length > 0 ? _c('a', {
    staticClass: "btn-outline btn-width-auto import__btn",
    staticStyle: {
      "margin-bottom": "10px"
    },
    on: {
      "click": _vm.downloadList
    }
  }, [_vm._v(" Скачать полный список ")]) : _vm._e(), _vm._l(_vm.errors, function (error, index) {
    return _c('div', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: error.name.startsWith('sku_range_price_list'),
        expression: "\n                                error.name.startsWith('sku_range_price_list')\n                            "
      }],
      key: index,
      staticClass: "error"
    }, [_vm._v(" " + _vm._s(error.text) + " ")]);
  }), _c('products-table', {
    attrs: {
      "id": _vm.getTableId(),
      "report-settings": _vm.reportSettings,
      "paginatable": !!_vm.$route.params.id,
      "configurable": false,
      "selectable": true,
      "total": _vm.productsTotal,
      "success": _vm.productsSuccess,
      "message": _vm.productsMessage
    },
    on: {
      "selectAction": _vm.removeProducts,
      "selectAction2": _vm.updateMinPrice,
      "selectAction3": _vm.updateMaxPrice,
      "selectAction4": _vm.updateExpectedPrice,
      "updateItem": item => _vm.updateProducts([item]),
      "addNew": _vm.addItem,
      "updateQuery": _vm.updateQuery
    },
    model: {
      value: _vm.products,
      callback: function ($$v) {
        _vm.products = $$v;
      },
      expression: "products"
    }
  })], 2)]), _c('button', {
    staticClass: "btn-blue mt32 save-btn",
    attrs: {
      "disabled": !_vm.saveEnabled
    },
    on: {
      "click": _vm.saveScript
    }
  }, [_vm._v(" Сохранить ")])], 2) : _c('div', [_c('data-loading', {
    attrs: {
      "title": 'Сейчас мы загружаем данные вашего магазина',
      "image": require('@/assets/images/wireframe.jpg'),
      "text1": 'На период загрузки отчеты пока недоступны, обычно этот процесс занимает 4-8 часов. Попробуйте вернуться позже в этот раздел.',
      "text2": 'Также по завершении загрузки мы отправим оповещение на ваш email'
    }
  })], 1), _c('modal-save-confirm', {
    ref: "modalSaveConfirm"
  }), _c('modal-delete-confirm', {
    ref: "modalDeleteConfirm"
  }), _c('modal-update-price', {
    ref: "modalUpdatePrice"
  })], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };