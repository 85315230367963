import { getMarketplacesListArray } from "@/utils/enums";
import Warning from "@/components/Alert/Warning";
import WarningAllLK from "@/components/Alert/WarningAllLK";

export default {
    data() {
        const select_marketplace_items = [...getMarketplacesListArray()];

        return {
            select_tokens_items: [],
            hasWb: false,
            hasOzon: false,
            tokens: [],
            select_marketplace_items,
            selectedTokens: this.$store.state.repricer.selectedTokens,
            marketplace: this.$store.state.repricer.marketplace,
            refreshInterval: null,
            tokensLoading: false,
            freezeSubscription: false,
        };
    },
    created() {
        this.loadTokens();
        this.refreshInterval = setInterval(() => {
            if (this.tokens.length === 0) {
                this.loadTokens();
            } else {
                if (this.refreshInterval) {
                    clearInterval(this.refreshInterval);
                    this.refreshInterval = null;
                }
            }
        }, 5000);
    },
    mounted() {
        if (!this.freezeSubscription) {
            this.subscriptionCheck(this.$store.state.connections.tokens);
        }
    },
    beforeDestroy() {
        if (this.refreshInterval) {
            clearInterval(this.refreshInterval);
            this.refreshInterval = null;
        }
    },
    methods: {
        subscriptionCheck(tokens) {
            if (tokens.some((item) => item.status === 60)) {
                this.freezeSubscription = true;
                this.$modal.open(() =>
                    import(
                        "@/components/modals/access/ModalTokensRepricerNoSubscription"
                    )
                );
            }
        },
        async marketplaceWatcher() {
            return 0;
        },
        getTableId() {
            return this._uid;
        },
        async loadTokens() {
            this.tokensLoading = true;
            try {
                let tokens = await this.$store.dispatch("repricer/getTokens");
                this.tokens = [];

                tokens?.data.forEach((item) => {
                    // if (
                    //     item.status === 30 &&
                    //     item.state !== 20 &&
                    //     item.initial_ready === 1
                    // ) {
                    this.tokens.push({
                        id: item.id,
                        title: item.name,
                        datasource: item.datasource,
                    });
                    if (item.datasource === "wb") {
                        this.hasWb = true;
                    }
                    if (item.datasource === "ozon") {
                        this.hasOzon = true;
                    }
                    // }
                });
                if (this.tokens.length > 0) {
                    this.chooseTokens();
                }
            } catch (e) {
                console.error(e);
            }
            this.tokensLoading = false;
        },
        chooseTokens() {
            if (!this.hasOzon && this.hasWb) {
                this.marketplace = this.select_marketplace_items[0];
            }
            if (this.hasOzon && !this.hasWb) {
                this.marketplace = this.select_marketplace_items[1];
            }
            this.select_tokens_items = [
                ...this.tokens.filter(
                    (item) => item.datasource === this.currentDatasource
                ),
            ];

            if (this.$store.state.repricer.selectedTokens?.length === 0) {
                this.selectedTokens = this.allMarketplaceTokens;
            } else {
                this.selectedTokens = this.$store.state.repricer.selectedTokens;
            }
        },
        changeTokens() {
            this.select_tokens_items = [
                ...this.tokens.filter(
                    (item) => item.datasource === this.currentDatasource
                ),
            ];
            this.selectedTokens = this.allMarketplaceTokens;
        },
    },
    computed: {
        loading() {
            return (
                !this.$store.state.repricer.hasInitialReadyTokens &&
                this.$store.state.repricer.hasValidTokens
            );
        },
        currentDatasource() {
            if (this.marketplace.title === "OZON") {
                return "ozon";
            }
            if (this.marketplace.title === "Wildberries") {
                return "wb";
            }
        },
        allMarketplaceTokens() {
            return [
                ...this.tokens.filter(
                    (item) => item.datasource === this.currentDatasource
                ),
            ];
        },
    },
    watch: {
        marketplace: {
            async handler(newValue, oldValue) {
                if (oldValue) {
                    this.changeTokens();
                    localStorage.setItem(
                        "repricerMp",
                        JSON.stringify(this.marketplace)
                    );
                    this.$store.dispatch(
                        "repricer/setMarketplace",
                        this.marketplace
                    );
                    if (newValue.id !== oldValue.id) {
                        await this.marketplaceWatcher();
                        this.$nextTick(() => {
                            this.changeTokens();
                        });
                    }
                }
            },
            immediate: true,
        },
        selectedTokens(val) {
            this.$store.dispatch("repricer/setSelectedTokens", val);
        },
        "$store.state.connections.tokens": function (val) {
            if (!this.freezeSubscription) {
                this.subscriptionCheck(val);
            }
        },
    },
    components: {
        Warning,
        WarningAllLK,
    },
};
