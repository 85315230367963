var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('modal', {
    ref: "modal",
    attrs: {
      "title": "Подключения магазина",
      "width": 1000,
      "actions": _vm.actions,
      "advanced": ""
    },
    scopedSlots: _vm._u([{
      key: "right",
      fn: function () {
        return [_c('span', {
          staticClass: "modal-right__title"
        }, [_vm._v("Инструкция по подключению")]), _c('p', {
          staticClass: "modal-right__text"
        }, [_vm._v(" Для подключения и работы с вашим магазином в SalesFinder вам потребуется API токен с определенным типом и разрешенными методами. "), _c('br'), _c('br'), _c('b', [_vm._v("Для Wildberries")]), _c('br'), _c('br'), _vm._v(" Используйте токен с включенными методами: Статистика + Аналитика + Цены и скидки, токен обязательно должен быть без пометки «Только на чтение»! ")]), _c('p', {
          staticClass: "modal-right__text"
        }, [_c('b', [_vm._v("Для Ozon")]), _c('br'), _c('br'), _vm._v(" Используйте токен с типом Admin, ключ обязательно должен быть без пометки «Только чтение (Read Only)»! ")]), _c('div', {
          staticStyle: {
            "display": "grid",
            "grid-template-columns": "1fr 1fr",
            "width": "100%"
          }
        }, [_c('img', {
          staticClass: "modal-right__img",
          staticStyle: {
            "margin-right": "10px"
          },
          attrs: {
            "src": require('@/assets/images/repricer/wildberries.jpg'),
            "alt": "New Token"
          }
        }), _c('img', {
          staticClass: "modal-right__img",
          attrs: {
            "src": require('@/assets/images/repricer/ozon.jpg'),
            "alt": "New Token"
          }
        })]), _c('a', {
          staticClass: "modal-right__link",
          attrs: {
            "href": "https://www.help.salesfinder.ru/repriser-token-connection",
            "target": "_blank"
          }
        }, [_vm._v(" Подробная инструкция ")])];
      },
      proxy: true
    }])
  }, [_c('ui-form', {
    staticStyle: {
      "padding-bottom": "35px"
    }
  }, [_c('div', {
    staticClass: "modal-item"
  }, [_c('div', {
    staticClass: "modal-item__label"
  }, [_vm._v("Название магазина")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.new_connection.name,
      expression: "new_connection.name"
    }],
    staticClass: "bold",
    attrs: {
      "type": "text",
      "placeholder": "Например: Мой магазин косметики",
      "autofocus": ""
    },
    domProps: {
      "value": _vm.new_connection.name
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.new_connection, "name", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "modal-item"
  }, [_c('div', {
    staticClass: "modal-item__label"
  }, [_vm._v("Маркетплейс")]), _c('app-select', {
    attrs: {
      "items": _vm.select_marketplace_items,
      "fullwidth": "",
      "disabled": !!_vm.mp
    },
    model: {
      value: _vm.new_connection.datasource,
      callback: function ($$v) {
        _vm.$set(_vm.new_connection, "datasource", $$v);
      },
      expression: "new_connection.datasource"
    }
  })], 1), _c('div', {
    staticClass: "modal-item"
  }, [_c('div', {
    staticClass: "modal-item__label"
  }, [_c('span', {
    staticClass: "settings__block__title"
  }, [_vm.new_connection.datasource.id === 'wb' ? _c('span', [_vm._v(" Токен API (Методы: Статистика + Аналитика + Цены и скидки) ")]) : _c('span', [_vm._v(" Токен API (Токен с типом Admin) ")]), _c('div', {
    staticClass: "spacer"
  }), [_c('fade-transition', [!_vm.validApiKey && _vm.mayError ? _c('div', {
    staticClass: "input-error"
  }, [_vm._v(" Некорректный формат токена, ключ должен содержать " + _vm._s(_vm.tokenLength) + " символов ")]) : _vm._e()]), _vm.validApiKey && _vm.mayError ? _c('img', {
    staticClass: "input-img",
    attrs: {
      "src": require(`@/assets/images/icons/success.svg`)
    }
  }) : _vm._e(), !_vm.validApiKey && _vm.mayError ? _c('img', {
    staticClass: "input-img img-error",
    attrs: {
      "src": require(`@/assets/images/icons/close.svg`)
    }
  }) : _vm._e()], _vm.new_connection.datasource.id === 'wb' ? _c('Tooltip', {
    staticClass: "tooltip",
    attrs: {
      "position": "top left"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-bottom": "5px"
    }
  }, [_vm._v(" Обратите внимание на правильный формат токена! ")]), _c('p', [_vm._v(" Для Wildberries нужно использовать токен с типом c методами «Статистика» + «Аналитика» + «Цены и скидки», без этого вы не сможете использовать все возможности интеграции с инструментами SalesFinder. ")])]) : _c('Tooltip', {
    staticClass: "tooltip",
    attrs: {
      "position": "top left"
    }
  }, [_c('p', {
    staticStyle: {
      "margin-bottom": "5px"
    }
  }, [_vm._v(" Обратите внимание на правильный формат токена! ")]), _c('p', [_vm._v(" Для Ozon нужно использовать токен с типом Admin, без этого вы не сможете использовать все возможности интеграции с инструментами SalesFinder. ")])])], 2)]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.new_connection.apikey,
      expression: "new_connection.apikey"
    }],
    staticClass: "bold",
    attrs: {
      "type": "text",
      "placeholder": _vm.tokenPlaceholder,
      "disabled": _vm.new_connection.datasource.id === 0
    },
    domProps: {
      "value": _vm.new_connection.apikey
    },
    on: {
      "blur": _vm.onBlurApiKey,
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.new_connection, "apikey", $event.target.value);
      }
    }
  })]), _vm.new_connection.datasource.id === 'ozon' ? _c('div', {
    staticClass: "modal-item"
  }, [_c('div', {
    staticClass: "modal-item__label"
  }, [_vm._v("Client ID")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.new_connection.client_id,
      expression: "new_connection.client_id"
    }],
    staticClass: "bold",
    attrs: {
      "type": "text",
      "placeholder": "Например: 157984",
      "disabled": _vm.new_connection.datasource.id === 0
    },
    domProps: {
      "value": _vm.new_connection.client_id
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.new_connection, "client_id", $event.target.value);
      }
    }
  })]) : _vm._e()])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };