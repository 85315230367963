var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "lists-page__none",
    class: {
      small: _vm.small
    }
  }, [!_vm.small ? _c('div', {
    staticClass: "lists-page__none-title"
  }, [_vm._v(" Управления ценами на основе автоматических сценариев. ")]) : _vm._e(), _vm.small ? _c('img', {
    staticClass: "loading__img",
    attrs: {
      "src": _vm.img
    }
  }) : _vm._e(), _vm.small ? _c('div', {
    staticClass: "lists-page__none-text",
    class: {
      small: _vm.small
    }
  }, [_vm._v(" " + _vm._s(this.text) + " ")]) : _c('div', {
    staticClass: "lists-page__none-text"
  }, [_vm.validTokens.length ? _c('div', [_vm._v(" В данный момент у Вас нет подходящих подключенных магазинов или они еще находятся в процессе сбора/подключения. "), _c('br'), _c('br'), _vm._v(" Убедитесь что магазин имеет статус \"Подключен\" и имеет соответствующие права. "), _c('br'), _c('br'), _vm._v(" Как подключить смотрите в инструкции тут или свяжитесь с нашей поддержкой. ")]) : _c('div', [_vm._v(" Подключите ваш магазин к SalesFinder для управления ценами на основе автоматических сценариев. "), _c('br'), _c('br'), _vm._v(" Репрайсер SalesFinder позволяет изменять цены на основе 4 стратегий: цены конкурентов, остатки товаров, динамика продаж и компенсация СПП. "), _c('br'), _c('br'), _vm._v(" Экономьте время и увеличивайте вашу прибыль с каждой продажи! ")])]), !_vm.validTokens.length ? _c('button', {
    staticClass: "btn-outline btn-width-auto",
    class: {
      'connect-btn': _vm.small
    },
    on: {
      "click": _vm.onCreateConnection
    }
  }, [_c('img', {
    attrs: {
      "src": require(`@/assets/images/icons/plus.svg`)
    }
  }), _vm._v(" Подключить магазин ")]) : _vm._e(), _c('modal-add-connection', {
    ref: "modalAddConnection",
    on: {
      "complete": _vm.onAdded
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };