var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.$store.state.prerender || _vm.$store.state.user.user && !_vm.$store.state.repricer.loaded ? _c('div') : !_vm.tokens.length ? _c('no-tokens', {
    attrs: {
      "small": _vm.small,
      "text": _vm.text,
      "img": _vm.img
    }
  }) : !_vm.hasAccess ? _c('div') : _c('div', [_vm._t("default")], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };