var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('label', [_c('input', {
    ref: "file",
    staticClass: "input-file",
    attrs: {
      "type": "file",
      "accept": ".xls,.xlsx"
    },
    on: {
      "change": _vm.handleFileSelect
    }
  }), _c('a', {
    staticClass: "btn-outline btn-width-auto import__btn"
  }, [_c('img', {
    attrs: {
      "src": require(`@/assets/images/icons/export.svg`),
      "width": "18",
      "height": "18"
    }
  }), _vm._v(" " + _vm._s(_vm.btnText) + " "), _vm.tooltip ? _c('Tooltip', {
    attrs: {
      "position": _vm.tooltipClasses
    }
  }, [_vm._v(" " + _vm._s(_vm.tooltip) + " ")]) : _vm._e()], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };