var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('modal', {
    ref: "modal",
    attrs: {
      "title": "Указать цену",
      "width": 420,
      "actions": _vm.actions
    }
  }, [_c('div', {
    staticClass: "modal-item"
  }, [_c('div', {
    staticClass: "modal-item__label"
  }, [_vm._v("Цена")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.price,
      expression: "price"
    }],
    staticClass: "bold",
    attrs: {
      "type": "text",
      "placeholder": "Цена",
      "autofocus": ""
    },
    domProps: {
      "value": _vm.price
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.price = $event.target.value;
      }
    }
  })])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };