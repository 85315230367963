import "core-js/modules/es.array.push.js";
import ModalAddConnection from "@/components/modals/ModalAddConnection.vue";
export default {
  props: {
    small: {
      type: Boolean,
      default: false
    },
    text: {
      type: String
    },
    img: {
      type: String
    }
  },
  data() {
    return {
      loading: this.$store.state.repricer.loading
    };
  },
  computed: {
    validTokens() {
      return this.$store.state.repricer.validTokens;
    }
  },
  methods: {
    onCreateConnection() {
      this.$access.hasOrThrow("repricer");
      this.$refs.modalAddConnection.open();
    },
    onAdded() {
      this.$router.push({
        name: "RepricerConnections"
      });
    }
  },
  components: {
    ModalAddConnection
  }
};