var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.notValidTokens.length && _vm.tokens.length ? _c('Warning', [_c('p', {
    staticStyle: {
      "margin-bottom": "10px"
    }
  }, [_vm._v(" Обратите внимание! У данных подключенных магазинов нет нужных прав для управления ценами: " + _vm._s(_vm.notValidTokensString) + " ")]), _c('p', [_vm._v(" Проверьте корректный формат формат токена, используя "), _c('a', {
    staticClass: "link",
    attrs: {
      "href": "https://www.help.salesfinder.ru/repriser-token-connection",
      "target": "_blank"
    }
  }, [_vm._v(" инструкцию ")]), _vm._v(" , или свяжитесь с "), _c('a', {
    staticClass: "link",
    attrs: {
      "href": "https://salesfinder.ru/contact",
      "target": "_blank"
    }
  }, [_vm._v(" поддержкой ")])])]) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };