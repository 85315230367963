import Modal, { ModalMixin } from "@/components/modals/layouts/Default.vue";
export default {
  mixins: [ModalMixin],
  data() {
    return {
      price: "",
      loading: false,
      onComplete: null
    };
  },
  mounted() {},
  methods: {
    onOpen({
      onComplete
    }) {
      this.price = "";
      this.onComplete = onComplete;
    },
    async submit({
      close
    }) {
      this.loading = true;
      try {
        this.loading = false;
        close();
        this.onComplete && this.onComplete(this.price);
        this.$emit("complete", this.price);
      } catch (e) {
        this.loading = false;
      }
    }
  },
  computed: {
    valid() {
      return this.price.length;
    },
    actions() {
      return ["cancel", {
        name: "submit",
        text: "Сохранить",
        action: ({
          close
        }) => {
          this.submit({
            close
          });
        },
        disabled: !this.valid,
        loading: this.loading
      }];
    }
  },
  components: {
    Modal
  }
};